
import {
  FAIL_CUENTA_UPDATE,
  REQUEST_CUENTA_UPDATE,
  SUCCESS_CUENTA_UPDATE,
} from "../constantes/cuentaConstantes";
import axiosInstance from "../lib/axiosConfig";
import { actualizarAccessToken } from "./sesionActions";


export const actualizarCuenta = (formData, setDisabledState) => async (dispatch, getState) => {
  dispatch({ type: REQUEST_CUENTA_UPDATE });

  try {
    setDisabledState(true);
    const {
      usuarioInfo: { token },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axiosInstance.put(
      "api/modificar-cuenta/",
      formData,
      config
    );

    dispatch({ type: SUCCESS_CUENTA_UPDATE, payload: data });
    setDisabledState(false);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(actualizarCuenta, formData));
    } else {
      dispatch({ type: FAIL_CUENTA_UPDATE, payload: error.message });
    }
    setDisabledState(false);
  }
};
