import React, { forwardRef } from "react";
import { BASE_URL } from "../../constantes/constantes";
import {
  StyledContenedorProducto,
  StyledGridDosSecciones,
  StyledLogo,
  StyledContenedorLogo,
  StyledPrincipal,
  StyledSeccionDatosLocal,
  StyledTexto,
} from "./styles/TicketVenta.styles";
import {
  sumarPreciosProductos,
  totalizarCantidadProductos,
  informacionTienda,
  obtenerFechaActualConFormato,
  getFormatoFecha,
  getFormatoHora,
} from "../../lib/utilis/salidaRutas";
import Separador from "../general/Separador";

const TicketVenta = forwardRef((props, ref) => {
  const { datosVenta } = props;

  // const datosVenta = null;

  if (datosVenta) {
    const productos = datosVenta.productos_venta;

    return (
      <StyledPrincipal ref={ref}>
        <StyledContenedorLogo>
          <StyledLogo src={`${BASE_URL}/static/imagenes/general/logo.png`} />
        </StyledContenedorLogo>
        <StyledTexto textAlign={"center"} fontWeight={"bolder"} fontSize={"20px"}>
          TICKET DE COMPRA
        </StyledTexto>
        <StyledGridDosSecciones>
          <StyledSeccionDatosLocal>
            <StyledTexto fontWeight={"bolder"} fontSize={"15px"} textAlign={"left"} bottom={"2px"}>
              DIRECCIÓN
            </StyledTexto>
            <StyledTexto
              textAlign={"left"}
              fontSize={"12px"}
              top={"1px"}
              bottom={"1px"}
              height={"20px"}
              fontWeight={"bolder"}
            >
              {informacionTienda.LOCAL.CALLE +
                " N° " +
                informacionTienda.LOCAL.NUMERO}
            </StyledTexto>
            <StyledTexto textAlign={"left"} fontWeight={"bolder"} fontSize={"12px"} top={"0px"} bottom={"1px"}>
              {informacionTienda.LOCAL.COLONIA}
            </StyledTexto>
            <StyledTexto textAlign={"left"} fontWeight={"bolder"} fontSize={"12px"} top={"0px"} bottom={"1px"}>
              {informacionTienda.LOCAL.MUNICIPIO +
                ", " +
                informacionTienda.LOCAL.ESTADO +
                " " +
                informacionTienda.LOCAL.CP}
            </StyledTexto>
          </StyledSeccionDatosLocal>
          <StyledSeccionDatosLocal>
            <StyledTexto
              fontWeight={"bolder"}
              fontSize={"15px"}
              textAlign={"center"}
              bottom={"2px"}
            >
              ATENCIÓN AL CLIENTE
            </StyledTexto>
            <StyledTexto
              textAlign={"left"}
              fontSize={"12px"}
              top={"1px"}
              bottom={"1px"}
              height={"20px"}
              fontWeight={"bolder"}
            >
              524 0028/01 800 GP HIELO
            </StyledTexto>
            <StyledTexto textAlign={"left"} fontWeight={"bolder"} fontSize={"11.8px"} top={"0px"} bottom={"1px"}>
              www.hielogranpacifico.com
            </StyledTexto>
            <StyledTexto textAlign={"left"} fontWeight={"bolder"} fontSize={"11.8px"} top={"0px"} bottom={"1px"}>
              contacto@hielogranpacifico.com
            </StyledTexto>
          </StyledSeccionDatosLocal>
        </StyledGridDosSecciones>
        <StyledTexto
          fontWeight={"bolder"}
          textAlign={"center"}
          fontSize={"12px"}
          top={"-2px"}
          bottom={"0px"}
          height={"14px"}
        >
          RFC: {informacionTienda.LOCAL.RFC}
        </StyledTexto>
        <Separador doble={true} />

        <StyledGridDosSecciones>
          <StyledTexto textAlign={"left"} fontWeight={"bolder"} fontSize={"13.5px"} top={"1px"} bottom={"0px"}>
            {getFormatoFecha(datosVenta.FECHA)}
          </StyledTexto>
          <StyledTexto textAlign={"center"} fontWeight={"bolder"} fontSize={"13.5px"} top={"1px"} bottom={"0px"}>
            {getFormatoHora(datosVenta.FECHA)}
          </StyledTexto>
        </StyledGridDosSecciones>

        <StyledTexto fontWeight={"bolder"} textAlign={"left"} fontSize={"13.5px"} top={"1px"} bottom={"0px"}>
          ID: {datosVenta.id}
        </StyledTexto>

        <StyledTexto
          fontWeight={"bolder"}
          textAlign={"left"}
          fontSize={"13.5px"}
          spanFontWeight={"bolder"}
          top={"-3px"}
          bottom={"3px"}
        >
          ATIENDE: <span>{datosVenta.VENDEDOR}</span>
        </StyledTexto>

        <StyledTexto
          fontWeight={"bolder"}
          textAlign={"left"}
          fontSize={"13.5px"}
          spanFontWeight={"bolder"}
          top={"-8px"}
          bottom={"0px"}
        >
          CLIENTE: <span>{datosVenta.NOMBRE_CLIENTE}</span>
        </StyledTexto>

        <StyledTexto
          fontWeight={"bolder"}
          textAlign={"left"}
          fontSize={"13.5px"}
          spanFontWeight={"bolder"}
          top={"1px"} bottom={"0px"}
        >
          PRODUCTOS: <span>{totalizarCantidadProductos(productos)}</span>
        </StyledTexto>
        <StyledTexto
          fontWeight={"bolder"}
          textAlign={"left"}
          fontSize={"13.5px"}
          spanFontWeight={"bolder"}
          top={"-5px"}
          bottom={"1px"}
        >
          TIPO PAGO: <span>{datosVenta.TIPO_PAGO}</span>
        </StyledTexto>
        <StyledTexto
          fontWeight={"bolder"}
          textAlign={"left"}
          fontSize={"13.5px"}
          spanFontWeight={"bolder"}
          top={"-8px"}
          bottom={"0px"}
          height={"14px"}
        >
          TIPO VENTA: <span>{datosVenta.TIPO_VENTA}</span>
        </StyledTexto>
        <Separador doble={true} />

        <StyledContenedorProducto>
          <StyledTexto
            fontWeight={"bolder"}
            textAlign={"center"}
            fontSize={"13.5px"}
            bottom= {"0px"}
          >
            PRODUCTO
          </StyledTexto>
          <StyledTexto fontWeight={"bolder"} textAlign={"left"} fontSize={"13.5px"} top={"1px"} bottom={"0px"}>
            CANT.
          </StyledTexto>
          <StyledTexto
            fontWeight={"bolder"}
            textAlign={"center"}
            fontSize={"13.5px"}
            top={"1px"} 
            bottom={"0px"}
          >
            PRECIO
          </StyledTexto>
          <StyledTexto
            fontWeight={"bolder"}
            textAlign={"center"}
            fontSize={"13.5px"}
            top={"1px"} 
            bottom={"0px"}
          >
            TOTAL
          </StyledTexto>
        </StyledContenedorProducto>

        <StyledContenedorProducto>
          {productos.map((producto) => {
            return (
              <>
                <StyledTexto
                  textAlign={"left"}
                  fontSize={"12px"}
                  bottom={"3px"}
                  fontWeight={"bolder"}
                >
                  {producto.NOMBRE_PRODUCTO}
                </StyledTexto>
                <StyledTexto
                  textAlign={"center"}
                  fontSize={"12px"}
                  bottom={"3px"}
                  fontWeight={"bolder"}
                >
                  {producto.CANTIDAD_VENTA}
                </StyledTexto>
                <StyledTexto
                  textAlign={"center"}
                  fontSize={"12px"}
                  bottom={"3px"}
                  fontWeight={"bolder"}
                >
                  $
                  {(producto.PRECIO_VENTA / producto.CANTIDAD_VENTA).toFixed(2)}
                </StyledTexto>
                <StyledTexto
                  textAlign={"center"}
                  fontSize={"12px"}
                  bottom={"3px"}
                  fontWeight={"bolder"}
                >
                  ${producto.PRECIO_VENTA.toFixed(2)}
                </StyledTexto>
              </>
            );
          })}
        </StyledContenedorProducto>
        <Separador />

        <StyledTexto textAlign={"center"} fontSize={"15px"} fontWeight={"bolder"} top={"-3px"} bottom={"0px"}>
          SUBTOTAL: ${sumarPreciosProductos(productos).toFixed(2)}
        </StyledTexto>
        <StyledTexto textAlign={"center"} fontSize={"15px"} fontWeight={"bolder"} top={"-3px"} bottom={"0px"}>
          DESCUENTO: {datosVenta.DESCUENTO.toFixed(2)}%
        </StyledTexto>
        <StyledTexto textAlign={"center"} fontSize={"19px"} fontWeight={"bolder"} top={"-3px"} bottom={"0px"}>
          TOTAL: ${datosVenta.MONTO}
        </StyledTexto>
        <Separador />

        <StyledTexto textAlign={"left"} fontWeight={"bolder"} fontSize={"11.4px"} top={"0px"} bottom={"6px"}>
          Es importante que conserve su ticket para hacer válida cualquier
          aclaración.
        </StyledTexto>
        <StyledTexto textAlign={"left"} fontWeight={"bolder"} fontSize={"11.4px"} top={"-7px"} bottom={"0px"}>
          En caso de NO recibir su ticket, quejas con el servicio o anomalías
          con su compra, comuníquese al teléfono de Atención al Cliente
        </StyledTexto>
        <StyledTexto textAlign={"left"} fontWeight={"bolder"} fontSize={"14px"} top={"0px"} bottom={"0px"}>
          FECHA DE REIMPRESIÓN: {obtenerFechaActualConFormato()}
        </StyledTexto>
        <Separador />

        <StyledTexto textAlign={"left"} fontWeight={"bolder"} fontSize={"14px"} top={"0px"} bottom={"0px"}>
          OBSERVACIONES:
          <br />
          {datosVenta.OBSERVACIONES}
        </StyledTexto>
      </StyledPrincipal>
    );
  } else {
    return (
      <StyledPrincipal ref={ref}>
        <h3> No existen datos de venta </h3>
      </StyledPrincipal>
    );
  }
});

export default TicketVenta;
