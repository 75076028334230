import React, { forwardRef } from "react";
import { BASE_URL } from "../../constantes/constantes";
import {
  StyledPrincipal,
  StyledTexto,
  StyledContenedorLogo,
  StyledLogo,
} from "./styles/TicketAvisoVisita.styles"; // You will need to define these styles based on your style guide
import { obtenerFechaActualConFormato } from "../../lib/utilis/salidaRutas";
import Separador from "../general/Separador";

const TicketAvisoVisita = forwardRef((props, ref) => {
  const { datosCliente, repartidor } = props;

  if (datosCliente) {
    return (
      <StyledPrincipal ref={ref}>
        <StyledContenedorLogo>
          <StyledLogo src={`${BASE_URL}/static/imagenes/general/logo.png`} />
        </StyledContenedorLogo>
        <StyledTexto textAlign={"center"} fontWeight={"bolder"} fontSize={"20px"}>
          AVISO DE VISITA
        </StyledTexto>
        <Separador doble={true} />

        <StyledTexto fontWeight={"bolder"} textAlign={"left"} fontSize={"13.5px"} bottom={"0px"} top={"0px"}>
          CLIENTE: {datosCliente.nombre}
        </StyledTexto>
        <StyledTexto fontWeight={"bolder"} textAlign={"left"} fontSize={"13.5px"} bottom={"0px"} top={"0px"}>
          REPARTIDOR: {repartidor}
        </StyledTexto>

        <StyledTexto textAlign={"left"} fontWeight={"bolder"} fontSize={"14px"} bottom={"0px"} top={"0px"}>
          FECHA: {obtenerFechaActualConFormato()}
        </StyledTexto>

        <StyledTexto textAlign={"left"} fontWeight={"bolder"} fontSize={"14px"} bottom={"0px"} top={"0px"}>
          OBSERVACIONES:  {datosCliente.observaciones}
        </StyledTexto>

        <StyledTexto textAlign={"center"} fontWeight={"bolder"} fontSize={"13.5px"} bottom={"0px"} top={"3px"}>
          Firma del Cliente
          <br />
          <br />
          <p style={{ marginBottom: "0px", fontWeight: "bolder"}}>
            _______________________________________________
          </p>
          <span style={{fontWeight:"bolder"}}>{datosCliente.nombre}</span>
        </StyledTexto>
      </StyledPrincipal>
    );
  } else {
    return (
      <StyledPrincipal ref={ref}>
        <h3>No existen datos del cliente</h3>
      </StyledPrincipal>
    );
  }
});

export default TicketAvisoVisita;
