import React, { forwardRef } from "react";
import { BASE_URL } from "../../constantes/constantes";
import {
  StyledContenedorProducto,
  StyledGridDosSecciones,
  StyledLogo,
  StyledContenedorLogo,
  StyledPrincipal,
  StyledTexto,
} from "./styles/TicketDevolucion.styles";
import {
  getFormatoFecha,
  getFormatoHora,
  obtenerFechaActualConFormato,
} from "../../lib/utilis/salidaRutas";
import Separador from "../general/Separador";

const TicketDevolucion = forwardRef((props, ref) => {
  const { datosDevolucion } = props;

  //   const datosSalidaRuta = null;

  if (datosDevolucion) {
    return (
      <StyledPrincipal ref={ref}>
        <StyledContenedorLogo>
          <StyledLogo src={`${BASE_URL}/static/imagenes/general/logo.png`} />
        </StyledContenedorLogo>
        <StyledTexto textAlign={"center"} fontWeight={"bolder"} fontSize={"20px"} bottom={"-6px"}>
          VALE DE DEVOLUCION
        </StyledTexto>
        <Separador doble={true} />

        <StyledGridDosSecciones>
          <StyledTexto
            textAlign={"left"}
            fontSize={"13.5px"}
            top={"0px"}
            bottom={"1px"}
            fontWeight={"bolder"}
          >
            {getFormatoFecha(datosDevolucion.FECHA)}
          </StyledTexto>
          <StyledTexto
            textAlign={"center"}
            fontSize={"13.5px"}
            top={"0px"}
            bottom={"1px"}
            fontWeight={"bolder"}
          >
            {getFormatoHora(datosDevolucion.FECHA)}
          </StyledTexto>
        </StyledGridDosSecciones>

        <StyledTexto
          fontWeight={"bolder"}
          textAlign={"left"}
          fontSize={"13.5px"}
          top={"0px"}
          bottom={"1px"}
        >
          ID: {datosDevolucion.id}
        </StyledTexto>

        <StyledTexto
          fontWeight={"bolder"}
          textAlign={"left"}
          fontSize={"13.5px"}
          spanFontWeight={"bolder"}
          top={"4px"}
          bottom={"0px"}
        >
          ENTREGA:
          <br />
          <br />
          <p style={{ marginBottom: "0px", fontWeight: "bolder" }}>
            ________________________________
          </p>
          <span style={{fontWeight:"bolder"}}>{datosDevolucion.ATIENDE}</span>
        </StyledTexto>

        <StyledTexto
          fontWeight={"bolder"}
          textAlign={"left"}
          fontSize={"13.5px"}
          spanFontWeight={"bolder"}
          top={"7px"}
          bottom={"-2px"}
        >
          RECIBE:
          <br />
          <br />
          <p style={{ marginBottom: "0px", fontWeight:"bolder" }}>
            ________________________________
          </p>
          <span>{datosDevolucion.REPARTIDOR}</span>
        </StyledTexto>

        <Separador doble={true} />

        <StyledContenedorProducto>
          <StyledTexto
            fontWeight={"bolder"}
            textAlign={"center"}
            fontSize={"13.5px"}
            top={"0px"}
            bottom={"1px"}
          >
            PRODUCTO: {datosDevolucion.PRODUCTO_NOMBRE}
          </StyledTexto>
          <StyledTexto
            fontWeight={"bolder"}
            textAlign={"center"}
            fontSize={"13.5px"}
            top={"0px"}
            bottom={"1px"}
          >
            CANT: {datosDevolucion.CANTIDAD_DEVOLUCION}
          </StyledTexto>
        </StyledContenedorProducto>
      </StyledPrincipal>
    );
  }
});

export default TicketDevolucion;
